/* Import 3L mixins and apply reset.css
********************************************/
@import '3L/3L';


/* Colors */
@borderColor: #d7d7d7;


@media (max-width: 767px)
{

  /* HEADER
  ********************************************/
  .companyLogo { display:none; }

  .topMenuContainer { margin-right:70px; }

  .mainDashboardNav { padding:0; }
  .mainDashboardNav li { display:block; .border-radius(0px); width:100%; }
  .mainDashboardNav > li > a { text-align:left; font-size:1.4em; }
  .mainDashboardNav > li > a > span { display:inline; margin:0 10px;  }

  .submenu { text-align:center; font-size:1.3em; }
  .quickSearchContainer { width:100%; }
  .quickSearchContainer .quickSearch { width:100%; }
  .quickSearchContainer .quickSearch:focus, .quickSearchContainer .quickSearch:active { width:100%; }


  /* COMMON STYLES
  ********************************************/
  .pageContent { padding:0 5px; }
  .pageContent > div { padding:0; }
  .submenuContainer { margin-bottom:10px; }

  .formHeader { line-height:inherit; padding:10px 15px; }
  .formHeader .description { display:block; }

  .curRecordsWrapper { padding:0; }

  tr:first-of-type { display:none; }
  tr, td { display:block; border:none !important; width:100% !important; }
  tr { padding:15px 0; }
  td { text-align:center; padding:0 !important; }

  #recordList { height:auto !important; }

  /* TRAINING
  ********************************************/
  .selectedResource .courseButtons { position:static; }
  .courseDescription { font-size:0.9em; text-align:center; }
  .seriesContainer { position:static; margin-top:10px;  }
  .seriesContainer .selectLabel { display:block; margin:auto; text-align:center; }
  .seriesContainer .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) { width:95%; margin:auto; display:block; }
  .courseListingsHeader { margin-top:20px; }


  /* ACCOUNT PAGE
  ********************************************/
  .accountPage .formPanel { height:auto; }


  /* EMAILS
  ********************************************/
  .formBody.emailsForm { padding:0; }
  .emailsForm .bootstrap-select { margin-left:15px !important; display:block !important; }
  .emailsForm .fieldGroupHeader { margin:15px; }


  /* REPORTS
  ********************************************/
  .learnerSearchContainer { margin:10px auto; }
  .learnerSearchContainer span { top:10px; }
}


@media (max-width: 420px)
{

  /* HEADER
  ********************************************/
  .submenuHeader { display:none; }
  .authenticationBar .navbar { height:100px; }
  .headerLogo { margin-top:8px; }
  .logoutLink { margin:0; position:absolute; right:15px; top:55px; }
  .logoutLink.currencyNotice { top:80px; font-size:0.8em; }
}