/*------------------------------------------------------------------------
# author    Eric Worman, HotRocket Development, Inc.
# copyright Copyright  2014. All rights reserved.
# @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
# Website   eTraining LMS
# Path: /resources/assets/less/site.less
-------------------------------------------------------------------------*/

/* Import 3L mixins and apply reset.css
********************************************/
@import '3L/3L';

.reset();

/* COLORS
********************************************/
/*
	Black: #1f1f1f / 31/31/31
*/

//Standard styles
@black: #333333;
@gray: #ebebeb;
@borderColor: #d7d7d7;
@darkGray: #757575; /* 117/117/117 */
@yellow: #F7F4CB; /* 247/244/203 */
@red: #F20000; /* 242/0/0 */
@disabled: #bdbdbd;

@ltGray: #eeeeee; /* 238/238/238 */
@teal: #019cb5; /* 1/156/181 */
@ltTeal: #8acfd1;
@darkTeal: darken(@teal, 20%);
@blue: #478ec7; /* 75/148/206 */
@darkBlue: #3b78a9; /* 59/120/69 */
@green: #216019; /* 33/96/25 */

@groupAdmin: #D4E3FA;

@bodyFont: 'Lato', sans-serif;
@serifFont: "Times New Roman", Times, serif;

@pageWidth: 90%;
@maxWidth: 1200px;

.dropShadow{
  .box-shadow(4px 4px 5px 0 @darkGray);
}



.placeholderColor(@arguments) {
  ::-webkit-input-placeholder { color:@arguments }
  ::-moz-placeholder { color:@arguments }
  :-ms-input-placeholder { color:@arguments }
}

/* COMMON STYLES
********************************************/
body {
  font-family: @bodyFont; font-weight: 300; color:@black; margin:auto; overflow-x:hidden;
}

a { color: inherit; }
a:hover { color:@teal; }
a.input-group-addon:hover { cursor:pointer; text-decoration:none; }

strong { font-weight:bold; }

.pageWidth { max-width:800px; margin:auto; position:relative; }

.baseForm{
  .form-group label { padding:5px 0; }
  .btn { padding:5px 10px; min-width:100px; margin:0 5px; }
}

.centerText { text-align:center; }

.loginBackground { background-color:#333; }

.title { font-size:2.3em; font-weight:bold; text-shadow:2px 2px 2px @gray; padding:5px 0; }
.subTitle { font-size:1.3em; font-weight:bold; margin-bottom:10px; }
.fieldErrorText { font-size:0.8em; font-weight:bold; color:red; padding-top:10px; text-align:left; clear:both; padding-left:15px; }
.fieldError label { color:red; }
.fieldError .form-control { background-color: @yellow; }
.file-input-name { display:inline; margin:5px 0 0 0; font-size:0.8em; }
.curImage { display: block; font-size:0.8em; }
.alert { width:95%; margin:20px auto 0 auto; }
.editRecord { color:black; padding:5px 0; display:block; }
.logo { display:block; margin:auto; }

.no-padding { padding:0; }
.description { color:@darkGray; font-size:0.5em; margin-top:5px; font-weight:300; }
.description .glyphicon { position:relative; top:1px; }

.form-group.inlineGroup {
  margin-top:30px;
  label { line-height: 24px; padding:5px 5px 0 0; display:inline-block; }
}

.inlineFieldGroup { margin: 15px 10px; }

.pageContent { padding:0 30px;  }
.formContainer { padding:0; }
.formPanel { border:1px solid @ltGray; .border-radius(3px); clear:both; margin: 0 0 25px 0; }
.formPanel .alert { margin:20px auto; }

.formHeader { background-color:@ltGray; .gradient(white,@ltGray); line-height:44px; padding:0 15px; .border-radius(3px 3px 0 0); font-weight:bold; font-size:1.5em; }
.formHeader.doubleLine { position:relative; line-height:inherit; padding:10px; }
.formHeader.doubleLine > .description { display:block; }

.formBody { padding:15px; }
.formLabel { font-weight:bold; text-align:right; font-size:0.9em; line-height:30px; }
.formLabel.twoLineLabel { line-height:1.1em; position:relative; top:1px; }
.fieldGroupHeader { font-weight:bold; color:@darkGray; padding:0 0 10px 0; border-bottom:1px solid @ltGray; margin:35px 0 20px 0;  }
.form-group p.description { font-size:0.8em; }
.fieldGroupHeader:first-of-type { margin-top:10px; }
.stackedInputField { margin-top:5px;}
.inputButtons .btn { margin-right:5px; }
.switchToggleContainer { position:relative; top:9px; }

.modal-title { font-size:2.3em; }

.infoPanelContainer { padding:10px; background-color:@ltGray; .border-radius(5px); margin-bottom:20px; }
.infoPanel { background-color:white; }
.infoPanelTitle { font-weight:700; color:@teal; font-size:1.3em; }
.infoPanelDescription { margin:5px 0 15px 0; }
.resourceItem { border:1px solid @borderColor; margin-bottom:-1px; position:relative; padding:10px 15px; }

.curRecordsWrapper { padding-left:20px; }
.curRecords {  border:1px solid @ltGray; .border-radius(3px); }
.records { margin:15px; border:1px solid @ltGray; .border-radius(3px); }
.curRecord { padding:10px; border-bottom:1px solid @ltGray; display:block; }
.curRecord.noRecordBorder { border:none; }
.curRecord:hover { background-color:@ltGray; }
.curRecord.disabled { color:@disabled; }

.records span.fa-shield { display:none; }
.records li.protectedRecord span.fa-shield { display:inline; }
.protectedRecord a { color:@red; }

.removeImageControls { padding:5px 0 0 15px; font-size:0.8em; clear:both; }
.success { color:@green; }
.disabledForm { color:@darkGray; }

.downloadLink { font-size:12px; font-weight:normal; line-height:48px; }
.logoutLink { color:white; margin:20px; }
.logoutLink:hover { color:@red; text-decoration:none; }
.resetPasswordLink { padding:10px 0 0 0; text-align:center; display:block; }

.errorOnlyContainer { margin-bottom:20px; }

.display-inline { display:inline-block !important; }


/* LOGIN FORM
********************************************/
.loginForm{
  max-width:350px; width:95%; margin:130px auto 30px auto; padding:20px; border:2px solid @gray; .border-radius(5px); background-color:white;
  .title { text-align: center; padding:20px 0; }
  .logo { width:100%; margin-bottom:20px;  }
  .alert { margin:20px auto; }
}


/* DASHBOARD HEADER
********************************************/
.headerLogo .brandingLogo { max-height: 50px; margin:10px 10px 10px 15px; }

.authenticationBar {
  background-color:@black;
  .nav { width:100px; margin-right:20px; }
  .navbar { margin-bottom:0; }
  .navbar-inverse .navbar-nav > li > a { color:white; }
  .loginNav, .notificationContainer { padding:15px 7px; position:relative; }
  .loginNav > a, .notificationContainer { color:white;  }
  .loginNav > a { padding:0; position:relative; top:-2px; }
  .notificationContainer .badge { background-color:@red; font-size:10px; margin-right:5px; position:relative; top:-2px; }
  .navbar-inverse { .border-radius(0); }
}

.mainDashboardNav {
  background-color:@black; padding:10px 10px 0 10px;
  li { background-color:@ltGray; .border-radius(7px 7px 0 0); text-align:center; width:95px; margin-right:1px; padding:13px 0; font-size:0.8em;  }
  li > a { color:@black; margin:0; padding:0; border:none; }
  li > a:hover { background-color:transparent; border:none; }
  li > a > span { display:block; }
  li:hover, .activeTab { background-color:@teal; }
  li:hover > a, li.activeTab > a { color:white; }
}

.nav.mainDashboardNav { width:100%; }

.submenuContainer { background-color:@teal; color:white; padding:8px 25px; margin:-2px 0 25px 0; }
.submenu { text-align:left; line-height:37px; display:none; }
.submenuHeader { display:inline-block; font-weight:400; font-size:1.2em; margin-right:20px;  }
.submenuItem { display:inline-block; margin:0 10px; }
.submenuItem a:hover { color:white; }
.submenuItem.active a { font-weight:bold; }

.dashboardContentContainer { min-height:500px; }

.quickSearchContainer {
  position: relative;
  .quickSearch { .border-radius(30px); width:200px; border:1px solid @ltTeal; font-style:italic; background-color:@teal; padding:10px 20px; color: white; .transition(all 1s); }
  .quickSearch:focus, .quickSearch:active { width:330px; }
  span { position:absolute; right:12px; top:10px; color:@ltTeal; }
}

.dashboardHeader {
  position:relative;

  .navbar-toggle { position:absolute; right:0; top:3px; background-color:white; }
  .navbar-toggle > .icon-bar { background-color:black; }
}



.companyLogo { position:absolute; right:30px; top:82px; }
.companyLogo img { max-height:50px; }


/* DASHBOARD FOOTER
********************************************/
.dashboardFooter { background-color:@teal; padding:20px 10px; color:white;  bottom:0; width:100%; font-size:0.8em; }

.clientAccountsPage {
  .formHeader > .description { position:relative; top:-2px; margin-left:10px; }
}



/* DASHBOARD
********************************************/

.statusPanel { background-color:@teal; color:@ltTeal; margin-bottom:30px; }
.statusPanel > span { font-size:40px; line-height:100px; letter-spacing:-0.5px; }
.statusPanel > .fa { color:@ltTeal; padding-left:30px; }
.statusPanel > .fa.error { color:@red; }
.statusPanel > .fa.success { color:@green; }

.statusPanelHeader { padding:15px; font-size:18px; color:white; background-color:@teal; display:block; }
.statusPanelHeader:hover { color:white; }
.statusValue { color:white; padding-right:30px; }

.chartsContainer { margin-top:30px; }
.chartsContainer .tab-pane { height:250px; width:100%; padding-top:15px; }
.chartsContainer .tab-content { padding-top:10px;  }
.chartsContainer .chart { width:90%; margin:0 auto 80px; position:relative; }
.flotTip {  background: #f74e4d none repeat scroll 0 0;  border: 1px solid #f74e4d; border-radius: 0.4em; color: rgb(255, 255, 255); padding: 0.6em; }
.chartTitle { margin-bottom:15px; font-size:2.1em; font-weight:400; color:@blue; }

.dateSelectorContainer { width:270px; position:absolute; right:0; top:30px;  }
.dateSelectorContainer label { float:left; margin-right:10px; line-height:30px; text-align:center; }
.dateSelectorContainer input { width:auto; }
.dateSelectorContainer .input-group-addon:hover { cursor:pointer; }

.tableDataContainer th { font-weight:bold; }

.coursesContainer td:nth-child(3) { width:140px; }
.coursesContainer td:nth-child(4) { width:130px; }



/* CLIENT ACCOUNTS
********************************************/
.customURLServer { color:@darkGray; padding:0; position:relative; top:10px; font-size:0.9em; }


/* LEARNERS
********************************************/
.learnersPage {
  .records { max-height: 1121px; }
  .records span.fa { display:none; }
  .records a.groupAdmin span.fa { display:inline; }
  .learnersImportForm .alert { margin: 10px auto 20px; }
}

.showLearnersPage {
  .noRecordFound { padding:25px; }
  .learnerRecord { font-weight:bold; text-align:left; }
  .learnerDetailsGroup { margin:20px auto; }
}

.courseSelectionContainer { margin-bottom:15px; }
.courseInactive { color:@red !important; }
.unassignCourse:hover { cursor:pointer; }
.unassignCourse:hover a { color: @teal;  }


/* IMPORT
********************************************/

.importResults { margin-bottom:15px; font-weight:bold; font-size:1.5em; }
.noGroupFound { color:@darkGray; font-style:italic; }
.importLearner { position:relative; top:-5px; }
.importLearners .modal-body { line-height: 1.6em; }

.importParametersContainer{
  padding:20px; position:relative;
  .fieldGroupHeader { text-align:left; }
  .display-inline { margin:0 10px; }
  .clientAlertWarning { position:absolute; right:4px; top:-44px; padding:10px; width:480px; margin:0; }
  .clientAlertWarning .close { position:relative; top:-5px; display:none; }
}


/* GROUPS
********************************************/
.learnersPanelError { text-align:center; margin-bottom:20px; }
.learnersPanel { margin-top:20px; }

.learnerSelectPanel{
  text-align:center; margin-bottom:20px;
  .learner { display:inline-block; width:258px; padding:10px 0; border:1px solid @borderColor; position:relative; margin-bottom:10px; text-align:center; }
  .learner:hover { cursor:pointer; background-color:@ltGray; }

  .learner > .fa { float:left; color:green; position:absolute; left:10px; display:none; }
  .learner.selected > .fa { display:inline; }
}


/* TRAINING
********************************************/
.coursesContainer { padding-top:20px; }
.courseTitle { font-weight:bold; font-size:1em; }
.coursePreviewImage { width:100%; margin:10px 0; }
.course { padding:5px; margin-bottom:20px; .border-radius(4px); border:1px solid @borderColor; }
.course .courseTitle { height:35px;}

.trainingPage .fieldGroupHeader { margin:30px 15px 0 15px; font-size:1.3em; }

li.courseDetails { color:@teal; display:inline-block; padding:0 10px; margin:15px 0; border-right:1px solid @teal; }
li.courseDetails:first-of-type { padding-left:0; }
li.courseDetails:last-of-type { border-right:none; }

.selectedResource {
  position:relative;
  .col-sm-7 { position:static; }
  .courseTitle { margin:10px 0; font-size:1.8em; }
  .courseButtons { float:right; margin-bottom:10px;  }
}

.courseDescription { line-height:1.5em; margin-top:10px; padding:5px;  }
.courseDescription > h1 { font-weight:bold; font-size:1.3em; }

.coursePreviewImageContainer { overflow:hidden; width:100%; height:270px;  }
.noCoursesFound { padding:20px; }
.noResources { padding:20px; }

.courseButtons { text-align:center; margin-top:15px; }
.courseButtons .btn { margin:0 3px ; }

.courseListingsHeader { position:relative; }
.courseListingsHeader .selectLabel { top:1px; right:5px; position:relative; }
.seriesContainer { position:absolute; right:15px; top:-15px; }
.completedContainer { position:absolute; right:255px; top:-15px; }
.hideCourse { display:none; }

.testingDisabled { color:@red; }

.incompleteCourse { color:@red; }

.paginationContainer { text-align:center; }
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover { background-color: @teal; }


/* QUIZZES
********************************************/

.curQuestions > .btn-group { padding-left:15px; }
.questionAlert { margin-bottom:20px; }

.infoPanelContainer.coursesContainer { margin-top:20px; padding:10px; }


.quizPage {
  .quizContainer { padding:5px 20px;  }
  .quizDescription{ font-size:0.8em; margin-bottom:20px; }
  .inputButtons { clear:both; margin:20px 0; }
  .alert.alert-warning, .alert.alert-success { margin:0 auto 20px;  }
  .divider { clear:both; }
  .formHeader { margin-bottom:20px; }
  .actionButton { background-color:@teal; color:white; }
}
.quizTitle { font-weight:bold; font-size:1.5em; margin-bottom:5px;  }
.questionWrapper { padding:15px 0; border-bottom:1px solid @borderColor; }
.questionWrapper.fieldError { background-color:@yellow; }
.questionsContainer {
  .checkbox, .radio { display:inline; padding:10px; }
}


.inlineAnswers {
  .answerBlock { float:right; }
  .questionTitle { float:left; }
  .radio label { padding:0 10px 0 0 ;}
}


/* REPORTS
********************************************/
.reportTableWrapper { padding:20px; }
.reportTableWrapper .description { font-size:12px; text-align:right; }
.reportContainer .formContainer { text-align:center; }
.reportContainer .formHeader { text-align:left; }
.reportContainer .btn-group { position:relative; top:12px; margin-right:15px;  }
.reportContainer .dropdown-menu { width:400px; }
.reportContainer .dropdown-menu .glyphicon-ok { visibility:hidden;}
.reportContainer .dropdown-menu .selected .glyphicon-ok { visibility:visible;}
.reportContainer .form-control { width:70%; margin:auto; }
.reportTableWrapper .table { text-align:left; }
.reportTableWrapper th { font-weight:bold; }

.loginTracking .groupAdmin { background-color: @groupAdmin; }


.downloadTrackingReport {
	td:nth-child(5) { text-align:center; width:135px;  }
	td:nth-child(6) { text-align:center; width:160px; }
}

.courseStatsReport {
	td { text-align:center; }
	td:nth-child(1), td:nth-child(2), td:nth-child(4) { text-align:left;  }
}

.course-incomplete { color:@red; }
.course-completed { color:@green; }

.learnerSearchContainer {
  margin-right:10px; top:-2px;
  .quickSearch { font-size:12px; font-weight:300; background-color:transparent; border:1px solid @borderColor; color:@darkGray; height:30px; padding:0 10px; }
  .quickSearch:focus, .quickSearch:active { width:200px; color:@black; }
  span { color:@darkGray; font-size:16px; top:18px; }
}

.quizResults {
  td:nth-child(7) { width: 175px; }
}

.quizResults .alert { text-align:left; }

.dataTables_wrapper {
	.bootstrap-select { position:relative; top:-3px; width:75px !important; margin:0 10px !important; }
}

.loadingDataPanel { font-size:32px; }





/* SETTINGS
********************************************/
.settingsPage .formContainer { padding:0 10px; }
.creditRanges .glyphicon { position:relative; top:40px; }
.creditCost .formLabel { display:block; text-align:left; }
.curCosts { width:95%; margin:15px auto; }
.curCosts td:nth-child(1), .curCosts td:nth-child(2) { width:25%; text-align:center; }
.curCosts th:nth-child(1), .curCosts th:nth-child(2) { width:25%; text-align:center;  }
.curCosts td:nth-child(3), .curCosts th:nth-child(3) { text-align:right; }
.curCosts td.noRecordsFound { text-align:left; }

.table-hover > tbody > tr.costRow:hover { background-color:@teal; color:white; cursor:pointer; }



/* ACCOUNTS
********************************************/
.accountStats {
  padding:10px;
  li { margin:40px 0; padding:0 15px; }
  li:first-of-type { margin-top:10px; }
  li:last-of-type { margin-bottom:10px; }
  .statHeading { font-size:1.2em; color:@darkGray; font-weight:bold; }
  .fa { font-size:42px; color:@gray; }
  .statDetails { position:relative; top:2px; }
  .stat { color:@teal; }

  .licensesExceeded .statHeading { color:@red; }
}

.licenseMonthlyCost { margin:5px 0; font-size:0.8em; }


/* LEARNER ACCOUNTS
********************************************/
.cardPanel { padding:20px; }
.cardPanel .alert { position:static; right:30px; bottom:37px; left:190px; width:auto; }
.accountPage .formPanel { height:490px; }
.accountPage .noRecordsFound { padding:20px; }
.accountPage .curCredits { position:relative; top:7px; }


/* COURSE LIBRARY
********************************************/
.assignCoursesPage {
  .formHeader { position:relative; line-height:inherit; padding:10px; }
  .formHeader > .description { display:block; }
  .seriesContainer { top:5px; right:5px; }
  .seriesContainer .selectLabel { font-size: 16px; font-weight:normal; }
  .coursesContainer { padding:20px 10px; }
  .coursesContainer li { padding:10px; }
  .coursesContainer li > a { display:block; height:176px; overflow:hidden; }
  .coursesContainer li > .fa { display:none; font-size:32px; position:absolute; z-index:100; top:100px; left:80px; color:@green; }
  .coursesContainer li:hover { background-color:@ltTeal; cursor:pointer; }
  .coursesContainer li.selected { background-color:@gray; }
  .coursesContainer li.selected > img { .opacity(0.6); }
  .coursesContainer li.selected > .fa { display:block; }
}

.curCourseAssignments { padding:10px; }
.courseAssignment { display:block; border:1px solid @borderColor; margin:5px; padding:10px;  }
.courseAssignment:hover { background-color:@ltGray; text-decoration:none; }

.assignCoursesForm label { width:100%; text-align:left; padding:0; }

.courseTitle { text-align:center; margin:10px 0 5px 0; }
.coursePreview { width:auto; max-width:100%; max-height:300px; display:block; margin:auto; }

.courseHistory {
  th { font-weight:bold; }
  th:nth-child(6), td:nth-child(6), th:nth-child(4), td:nth-child(4), th:nth-child(5), td:nth-child(5) { text-align:center; }
}



/* SUPPORT
********************************************/

.supportPage{
  .curRecord { font-size:0.8em;  }
  textarea.comments { height:100px; }
  .ticketComments { font-size: 20px; padding:10px; .border-radius(5px); margin:10px auto;  }
  .adminComment { font-style:italic; text-align:right; color:white; }
  .ticketCommentsWrapper { margin:25px auto 15px; border-bottom:1px solid @borderColor; padding-bottom:15px; }
  .ticketCommentDetails { display:block; font-size:14px; margin-top:5px; }
}

.manageSupportTickets { min-height:600px; }
.existingTickets .fa-certificate { display:none; }
.existingTickets .newResponse .fa-certificate { display:inline; }

.existingTickets {
  .new { color:@red; }
  .closed { color: #a6a6a6; }
  .updated { color:@blue; }
}

